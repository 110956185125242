import css from "./TextOverImage.module.scss";
import { setHost } from "../../../util/setHost.js";
import { BackgroundColorOptions } from "./../../Elements/BackgroundColorOptions/BackgroundColorOptions.jsx";
const host = setHost();

export const TextOverImage = ({
  headerText,
  headerSize,
  media,
  backgroundColor,
}) => {
  const backgroundOptionColor = BackgroundColorOptions({
    optionColor: backgroundColor?.colors,
  });

  return (
    <div
      className={`${css.textOverImageWrapper} ${backgroundOptionColor} wrapped full noPadding`}
    >
      <div className="uiRenderer">
        <div className="wrapped">
          <div className={css.toiContent}>
            <h2 className={`large`}>{headerText}</h2>
            <img
              src={host + media?.data?.attributes?.url}
              alt={media?.alternativeText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
